import domReady from '@roots/sage/client/dom-ready';
import { init_app } from './src/app';
import Swiper from 'swiper';

/**
 * Application entrypoint
 */
domReady(async () => {
  init_app();
  // const swiper = new Swiper('.swiper-container', {
  //   // Add your Swiper options here
  //   direction: 'horizontal',
  //   loop: true,
  //   // other options...
  // });
  Swiper;


  // ...
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
// @ts-ignore
if ((import.meta as any).webpackHot) {
  (import.meta as any).webpackHot.accept(console.error);
}
